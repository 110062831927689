import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilBell,
  cilCalculator,
  cilChartPie,
  cilCursor,
  cilDescription,
  cilDrop,
  cilNotes,
  cilPencil,
  cilPuzzle,
  cilSpeedometer,
  cilStar,
} from "@coreui/icons";
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react";

const _navItems = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/dashboard",
  },
  {
    component: CNavItem,
    name: "Online Clients",
    to: "/online-clients",
  },
  {
    component: CNavItem,
    name: "All Time Clients",
    to: "/all-time-clients",
  },
  {
    component: CNavItem,
    name: "Heatmap",
    to: "/heatmap",
  },
];

export default _navItems;
