import React from "react";

const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"));
const Heatmap = React.lazy(() => import("./views/Heatmap/Heatmap"));
const AllTimeClients = React.lazy(() =>
  import("./views/AllTimeClients/AllTimeClients.js")
);
const OnlineClients = React.lazy(() =>
  import("./views/OnlineClients/OnlineClients.js")
);

const routes = [
  { path: "/", exact: true, name: "Home" },
  { path: "/dashboard", name: "Dashboard", element: Dashboard },
  { path: "/heatmap", name: "Heatmap", element: Heatmap },
  {
    path: "/all-time-clients",
    name: "All Time Clients",
    element: AllTimeClients,
  },
  {
    path: "/online-clients",
    name: "Online Clients",
    element: OnlineClients,
  },
];

export default routes;
