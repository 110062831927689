import React from "react";
import { NavLink } from "react-router-dom";
//store
import { useSideBarStore } from "src/store/sideBar";
//CoreUi components
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMenu } from "@coreui/icons";

//images
import logoQity from "src/assets/brand/Logo-qity.png";
import HeaderSearch from "./header/HeaderSearch";

const AppHeader = () => {
  const sidebarShow = useSideBarStore((state) => state.show);
  const setSideBarShow = useSideBarStore((state) => state.toggleSideBar);

  return (
    <CHeader position='sticky' className='mb-4'>
      <CContainer fluid>
        <CHeaderToggler
          className='ps-1'
          onClick={() => setSideBarShow(!sidebarShow)}>
          <CIcon icon={cilMenu} size='lg' />
        </CHeaderToggler>
        <HeaderSearch />
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;
