import React from "react";
//components
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
} from "@coreui/react";
import { AppSidebarNav } from "./AppSidebarNav";
import SimpleBar from "simplebar-react";
// sidebar nav config
import _navItems from "../components/Nav/_navItems";
import { useSideBarStore } from "src/store/sideBar";
//icons
import logoQity from "src/assets/brand/Logo-qity.png";

import "simplebar/dist/simplebar.min.css";

const AppSidebar = () => {
  const sidebarShow = useSideBarStore((state) => state.show);
  const unfoldable = useSideBarStore((state) => state.sidebarUnfoldable);
  const setSideBarShow = useSideBarStore((state) => state.toggleSideBar);
  const setUnfoldable = useSideBarStore((state) => state.setSideBarUnfoldable);
  return (
    <CSidebar
      position='fixed'
      unfoldable={!unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        setSideBarShow(visible);
      }}>
      <CSidebarBrand className='d-none d-md-flex' to='/'>
        {unfoldable && sidebarShow ? (
          <img src={logoQity} height='48' alt='logo' />
        ) : (
          <img src={logoQity} height='35' alt='logoMini' />
        )}
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={_navItems} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className='d-none d-lg-flex'
        onClick={() => setUnfoldable(!unfoldable)}
      />
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
