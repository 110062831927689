import React from "react";
//styles
import "../../styles/components/HeaderSearch.scss";
//icons
import { cilSearch } from "@coreui/icons";
//components
import { CFormInput, CInputGroup, CInputGroupText } from "@coreui/react";
import CIcon from "@coreui/icons-react";
// this component should search Client and Site ID
const HeaderSearch = () => {
  return (
    <div className={"search-wrapper"}>
      <CInputGroup className='search-wrapper__group'>
        <CFormInput
          className={"search-wrapper__input"}
          type='text'
          placeholder='Search'
          autoComplete='search-history'
        />
        <CInputGroupText className={"search-wrapper__input__text"}>
          <CIcon icon={cilSearch} size='xl' />
        </CInputGroupText>
      </CInputGroup>
    </div>
  );
};

export default HeaderSearch;
