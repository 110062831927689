import React from "react";
import { CFooter } from "@coreui/react";

const AppFooter = () => {
  return (
    <CFooter>
      <div className='mx-auto'>
        <a
          href='#'
          //  target='_blank'
          rel='noopener noreferrer'>
          WIFI BACK OFFICE
        </a>
        <span className='ms-1'>&copy; 2022 WIFI BACK OFFICE.</span>
      </div>
      {/* <div className='ms-auto'>
        <span className='me-1'>Powered by</span>
        <a href="https://coreui.io/react" target="_blank" rel="noopener noreferrer">
          CoreUI React Admin &amp; Dashboard Template
        </a>
      </div> */}
    </CFooter>
  );
};

export default React.memo(AppFooter);
