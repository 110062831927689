import React, { Suspense } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
//styles
import "./styles/scss/style.scss";
import Home from "./views/Home/Home";
import {useAuthStore} from "./store/auth";
// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const Password = React.lazy(() => import("./views/pages/Password/Password"));

const loading = (
  <div className='pt-3 text-center'>
    <div className='sk-spinner sk-spinner-pulse'></div>
  </div>
);

const App = () => {

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>

          <Route exact path='/' name='Login Page' element={<Login />} />
          <Route
            exact
            path='/password'
            name='Change Password'
            element={<Password />}
          />
          <Route
            exact
            path='/register'
            name='Register Page'
            element={<Register />}
          />
          <Route exact path='/404' name='Page 404' element={<Page404 />} />
          <Route exact path='/500' name='Page 500' element={<Page500 />} />
          <Route path='*' name='Home' element={<Home />} />
        </Routes>
      </Suspense>
    </HashRouter>
  );
};

export default App;
